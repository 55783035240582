<template>
  <div class="iframe_page">
    <iframe :src="url" frameborder="0" width="100%" :height="height"></iframe>
  </div>
</template>
<script>
export default {
  data() {
    return {
      url: "",
      height: ""
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    //所有jquery页面判断iframe路径
    fetchData: function() {
      switch (this.$route.params.name) {
        case "treasure":
          this.url = this.allFn.getUrlVerify("//user.ggcj.cn/fortune/account.html");
          this.height = "600px";
          break;
        case "consumption":
          this.url = this.allFn.getUrlVerify("//user.ggcj.cn/fortune/account_detail.html");
          this.height = "600px";
          break;
        case "pwd":
          this.url = this.allFn.getUrlVerify("//user.ggcj.cn/securityCenter/userCenter/modifyPassword.html");
          this.height = "600px";
          break;
        case "bindPhone":
          this.url = this.allFn.getUrlVerify("//user.ggcj.cn/securityCenter/userCenter/telephoneBind.html");
          this.height = "600px";
          break;
      }
    }
  },
  watch: {
    $route: "fetchData"
  }
};
</script>
<style lang="scss">
.iframe_page {
  background-color: #ffffff;
}
</style>